export const casesSlider = ()=> {
    const casesSwiper = new Swiper('.cases__slider', {
        speed: 1000,
        effect: 'fade',
        autoHeight: true,
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: '.cases__next',
            prevEl: '.cases__prev',
        },
    });
}