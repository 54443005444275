import {variables} from "../vars.js";

export const mobileMenu = ()=> {
    const burger = document.querySelector('.burger')
    const mobileMenu = document.querySelector('.mobile__menu')

    burger.addEventListener('click',()=> {
        burger?.classList.toggle('active');
        mobileMenu?.classList.toggle('active');
        variables.bodySite?.classList.toggle('overflow-y-hidden');
    })
}