import {smoothScrollTo} from "./smooth-scroll.js";
import {variables} from "../vars.js";

export const anchorScroll = ()=> {
    const anchorBtns = document.querySelectorAll('.anchor-scroll-js')
    const anchorMenusBtns = document.querySelectorAll('.anchor-scroll-menu-js > a')
    const headerHeight = variables.header.getBoundingClientRect().height

    if (window.location.hash) {
        const hashElement = document.querySelector(window.location.hash)
        console.log(hashElement.getBoundingClientRect())
        console.log(hashElement.offsetTop)
        smoothScrollTo(hashElement.getBoundingClientRect().top - headerHeight,2)

    }

    anchorBtns.forEach((item)=> {

        item.addEventListener('click',(e)=> {
            e.preventDefault()
            const itemHref = item.getAttribute('href')
            const itemTarget = document.querySelector(itemHref)
            const itemOffset = itemTarget.getBoundingClientRect().top - headerHeight
            smoothScrollTo(itemOffset,2)
        })
    })

    anchorMenusBtns.forEach((item)=> {

        item.addEventListener('click',(e)=> {
            e.preventDefault()
            const itemHref = item.getAttribute('href')
            const itemTarget = document.querySelector(itemHref)
            setTimeout(()=> {
                variables.header.classList.remove('active')
            },500)
            variables.bodySite.classList.remove('overflow-y-hidden')
            document.querySelector('.mobile__menu').classList.remove('active')
            document.querySelector('.burger').classList.remove('active')
            if (itemTarget == null) {
                const url = window.location.href;
                if(url.includes('/en/')){
                    window.location.href = window.location.origin + '/en/'+itemHref
                    window.history.pushState({},null, '');
                } else {
                    window.location.href = window.location.origin + '/'+itemHref
                    window.history.pushState({},null, '');
                }
            } else {
                const itemOffset = itemTarget.getBoundingClientRect().top - headerHeight
                smoothScrollTo(itemOffset,2)
            }
        })
    })

}