export const formSetting = ()=> {
    //send

    const contactForm = document.querySelector('.contacts__form')
    const contactThanks = document.querySelector('.contacts__thanks')
    const contactInputsWrapper = document.querySelectorAll('.contacts form .wpcf7-form-control-wrap')

    contactForm?.querySelector('form').addEventListener('wpcf7mailsent',()=> {
        contactThanks?.classList.add('active');
        contactForm?.classList.add('hide')
    })

    // validation

    contactInputsWrapper?.forEach((item,index)=> {
        item.querySelector('input')?.addEventListener('change',()=> {
            setTimeout(()=> {
                if (item.querySelector('input').classList.contains('wpcf7-not-valid')) {
                    item?.classList.add('not-valid')
                } else {
                    if (item.classList.contains('not-valid')) {
                        item?.classList.remove('not-valid')
                        item?.classList.add('valid')
                    }
                }
                if (item.querySelector('input').value != '' && !item.querySelector('input').classList.contains('wpcf7-not-valid')) {
                    item?.classList.add('valid')
                } else {
                    item?.classList.remove('valid')
                }
            },100)
        })
    })
    contactForm?.querySelector('form').addEventListener("wpcf7submit", ()=> {
        setTimeout(()=> {
            contactInputsWrapper?.forEach((item,index)=> {
                if (item.querySelector('input')?.classList.contains('wpcf7-not-valid')) {
                    item?.classList.add('not-valid')
                } else {
                    if (item?.classList.contains('not-valid')) {
                        item?.classList.remove('not-valid')
                        item?.classList.add('valid')
                    }
                }
                try {
                    if (item.querySelector('input').value != '' && !item.querySelector('input').classList.contains('wpcf7-not-valid')) {
                        item?.classList.add('valid')
                    } else {
                        item?.classList.remove('valid')
                    }
                } catch (error) {}
            })
        },300)
    });
}
