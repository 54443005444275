export const aboutSlider = ()=> {
    const swiperAbout = new Swiper('.about__slider', {
        speed: 1000,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        autoHeight: true,
        pagination: {
            el: '.about__pagination',
        },

        navigation: {
            nextEl: '.about__slider-next',
            prevEl: '.about__slider-prev',
        },
    });
    const tabs = document.querySelectorAll('.about__tab');
    const images = document.querySelectorAll('.about__image');
    const aboutWrapper = document.querySelector('.about__wrapper');

    swiperAbout?.on('slideChange', function() {
        tabs?.forEach((item)=> {
            item?.classList.remove('active');
        })
        tabs[swiperAbout.realIndex]?.classList.add('active');
        images?.forEach((item)=> {
            item?.classList.remove('active');
        })
        images[swiperAbout.realIndex]?.classList.add('active');
        aboutWrapper?.classList.remove('bgc-1','bgc-2','bgc-0');
        aboutWrapper?.classList.add('bgc-'+swiperAbout.realIndex);
    });

    tabs?.forEach((item,index)=> {
        item?.addEventListener('click',()=> {
            swiperAbout?.slideTo(index)
            images?.forEach((item)=> {
                item?.classList.remove('active');
            })
            images[index]?.classList.add('active');
        })
    })
}