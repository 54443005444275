import {aboutSlider} from "./modules/sliders/about-slider.js";
import {mobileMenu} from "./modules/mobile-menu.js";
import {casesSlider} from "./modules/sliders/cases-slider.js";
import {formSetting} from "./modules/form.js";
import {anchorScroll} from "./modules/anchor-scroll.js";

document.addEventListener("DOMContentLoaded", function (event) {

    mobileMenu();

    // sliders

    aboutSlider();

    casesSlider()

    formSetting();

    anchorScroll();

});